import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import $ from "jquery";

const ProwadzenieFacebookPage = ({ location: { pathname } }) => {
  const VictorBtnAction = (e) => {
    e.preventDefault();
    $('#facebookPackage option[value="Pakiet Victor FB"]').attr(
      "selected",
      "selected"
    );
    $("#facebookPackage").css("color", "black");
  };

  const WinnerBtnAction = (e) => {
    e.preventDefault();
    $('#facebookPackage option[value="Pakiet Winner FB"]').attr(
      "selected",
      "selected"
    );
    $("#facebookPackage").css("color", "black");
  };

  const ChampionBtnAction = (e) => {
    e.preventDefault();
    $('#facebookPackage option[value="Pakiet Champion FB"]').attr(
      "selected",
      "selected"
    );
    $("#facebookPackage").css("color", "black");
  };

  return (
    <Layout
      seo={{
        title: "Prowadzenie strony na Facebooku, Strona firmowa Facebook",
        description:
          "Stwórz stronę swojej firmy na Facebooku. Zbuduj zaangażowaną społeczność wokół swojej marki i twórz spójny wizerunek marki. Zrealizuj swoje cele biznesowe we współpracy z eksperami social media When. ",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Prowadzenie strony <br /> <span>na Facebooku</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#social-media">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Social Media:</p>
            <ul>
              <li>
                <Link to="/audyt-kanalow-social-media/">
                  Audyt kanałów social media
                </Link>
              </li>
              <li>
                <Link to="/strategia-komunikacji-social-media/">
                  Strategia komunikacji <br /> social media
                </Link>
              </li>
              <li className="current">
                <a href="#home">Prowadzenie strony na Facebooku</a>
              </li>
              <li>
                <Link to="/prowadzenie-konta-instagram/">
                  Prowadzenie konta Instagram
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-strony-na-linkedin/">
                  Prowadzenie strony na LinkedIn
                </Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              {/* <li>
                <Link to="/chatbot-w-komunikacji-marki/">
                  Chatbot w komunikacji marki
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-3">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Największa społeczność w social media
                </h2>
                <p className="text-col__desc">
                  Nawiąż wartościowy kontakt z potencjalnymi klientami, buduj
                  zaangażowaną społeczność wokół swojej marki i twórz jej
                  spójny, zaplanowany wizerunek. Niezależnie od tego czy Twoim
                  celem jest generowanie sprzedaży, pozyskiwanie leadów czy
                  świadomość marki, to właśnie Facebook, jak żaden inny kanał,
                  może realizować wszystkie cele biznesowe Twojej firmy w tym
                  samym czasie. A my Ci w tym pomożemy.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/facebook-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/facebook-img-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Partnerzy <br /> w sukcesie
                </h2>
                <p className="text-col__desc">
                  W naszym zespole odnajdziesz wyłącznie doświadczonych,
                  zaangażowanych Specjalistów Social Media, którzy jak nikt inny
                  rozumieją wagę słowa pisanego i potencjał social media dla
                  rozwoju Twojego biznesu.
                  <br />
                  <br />
                  Odkryjesz u nas jednocześnie fascynację z prowadzonego
                  projektu, kreatywność i odwagę w proponowanych rozwiązaniach,
                  wiedzę na temat najnowszych rozwiązań w dziedzinie social
                  media, ale również twarde umiejętności analityczne. To właśnie
                  dzięki nim obiektywnie i wnikliwie podchodzimy do analizy
                  osiąganych efektów i realizacji wyznaczonych celów.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-counter">
        <div className="row">
          <div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
            <h2 className="single-offer-section-counter__text">
              Ponad
              {/* <CountUp
                            start={0}
                            end={19500000}
                            duration={5}
                            separator=" "
                        /> <br/> */}
              <CountUp start={0} end={19500000} separator=" " duration={2}>
                {({ countUpRef, start }) => (
                  <div className="counter-wrapper">
                    <span ref={countUpRef} />
                    <Waypoint onEnter={start} />
                  </div>
                )}
              </CountUp>{" "}
              <br />
              aktywnych <br />
              użytkowników w Polsce
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-big-img single-offer-section-big-img--facebook">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <div className="image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/images/facebook-graph.png")}
                alt=""
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/images/facebook-graph-mobile.png")}
                alt=""
              />
              <p className="image-container__text text text--1">
                Tylko doświadczeni <br /> Specjaliści Social Media
              </p>
              <p className="image-container__text text text--2">
                Niebanalne <br /> szaty graficzne
              </p>
              <p className="image-container__text text text--3">
                Spójne strategie <br /> komunikacji
              </p>
              <p className="image-container__text text text--4">
                Partnerska współpraca
              </p>
              <p className="image-container__text text text--5">
                Kampanie <br /> Facebook Ads <br /> nastawione <br /> na cele
                biznesowe
              </p>
              <p className="image-container__text text text--6">
                Kreatywne <br /> Big Idea
              </p>
              <p className="image-container__text text text--7">
                Najnowsze <br /> rozwiązania
              </p>
              <ul className="big-bullets list-mobile">
                <li>Tylko doświadczeni Specjaliści Social Media</li>
                <li>Niebanalne szaty graficzne</li>
                <li>Spójne strategie komunikacji</li>
                <li>Partnerska współpraa</li>
                <li>Kampanie Facebook Ads nastawione na cele biznesowe</li>
                <li>Kreatywne Big Idea</li>
                <li>Najnowsze rozwiązania</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-timeline single-offer-section-timeline--facebook">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <div className="header-container">
              <div className="custom-row">
                <div className="left-col">
                  <img
                    src={require("../assets/images/ico-arrow-timeline.svg")}
                    alt=""
                    className="header-container__arrow"
                  />
                </div>
                <div className="right-col">
                  <h3 className="header-container__title">
                    Zobacz ścieżkę naszego wspólnego sukcesu
                  </h3>
                </div>
              </div>
            </div>
            <div className="timeline-container">
              <span className="timeline-container__line"></span>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">1</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Analiza</p>
                    <p className="single-item__desc">
                      Przeanalizujemy dotychczasowe działania Twojej marki oraz
                      konkurencji na portalu Facebook.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">2</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Strategia komunikacji</p>
                    <p className="single-item__desc">
                      W ramach naszej współpracy opracujemy strategię
                      komunikacji dopasowaną do grupy docelowej Twojej marki,
                      stworzymy tematyczne cykle postów oparte o big idea i
                      zaproponujemy wyróżniającą się szatę graficzną.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">3</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Harmonogram postów</p>
                    <p className="single-item__desc">
                      Przed rozpoczęciem każdego kolejnego miesiąca otrzymasz od
                      nas harmonogram postów na dany miesiąc. Zajmiemy się
                      tworzeniem grafik, treści, publikacją postów i moderacją
                      komentarzy, dzięki czemu Ty możesz zająć się w firmie tym,
                      co dla Ciebie najważniejsze.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">4</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">
                      Rozwijanie społeczności
                    </p>
                    <p className="single-item__desc">
                      Prowadzone działania będą przez są wspierane precyzyjnie
                      zaplanowaną reklamą Facebook Ads, z nastawieniem na Twoje
                      cele biznesowe - w zależności od nich będziemy rozwijać
                      społeczność na Twojej stronie, zwiększać rozpoznawalność
                      Twojej marki, generować leady od grupy docelowej lub
                      sprzedaż w Twoim sklepie e-commerce.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">5</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Raport i rekomendacje</p>
                    <p className="single-item__desc">
                      Nie spoczywamy na laurach. Każdy miesiąc kończymy nie
                      tylko pełnym raportem z przeprowadzonych działań i efektów
                      kampanii, ale przede wszystkim dalszymi rekomendacjami w
                      kierunku maksymalizacji potencjału Twojej marki na
                      Facebooku.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-pricing-table">
        <div className="row">
          <div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
            <h2 className="single-offer-section-pricing-table__title">
              Wybierz rozwiązanie dla siebie
            </h2>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th>
                      <span>Victor</span>
                    </th>
                    <th>
                      <span>Winner</span>
                    </th>
                    <th>
                      <span>Champion</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="row-label">Kanał social media</td>
                    <td>
                      Facebook <br /> lub Instagram
                    </td>
                    <td>
                      Facebook <br /> lub Instagram
                    </td>
                    <td>
                      Facebook <br /> + Instagram
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label">Audyt obecnej kondycji</td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label">
                      Przygotowanie strategii komunikacji wraz z pomysłami
                      kreatywnymi oraz propozycją szaty graficznej
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label">Analiza konkurencji</td>
                    <td>
                      2 stron FB <br /> lub 2 konta IG
                    </td>
                    <td>
                      4 strony FB <br /> lub 4 konta IG
                    </td>
                    <td>
                      3 strony FB <br /> +3 konta IG{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label">
                      Utworzenie, aktualizacja lub optymalizacja konta
                      reklamowego FacebookAds{" "}
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label">
                      Konfiguracja analityki internetowej na potrzeby analizy
                      skuteczności prowadzonych kampanii
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label">Liczba postów w miesiącu</td>
                    <td>10</td>
                    <td>20</td>
                    <td>24 (po 12/kanał)</td>
                  </tr>
                  <tr>
                    <td className="row-label">Indywidualny opiekun</td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label">
                      Organizacjia konkursów wraz z reuglaminem
                    </td>
                    <td></td>
                    <td>1</td>
                    <td>od 2 w miesiącu</td>
                  </tr>
                  <tr>
                    <td className="row-label row-tooltip row-tooltip--1">
                      Moderacja od 8 do 16 w dni robocze
                      <span className="star">*</span>
                      <div className="tooltip tooltip--1">
                        <span className="star">*</span> Możliwość moderacji w{" "}
                        <br /> weekendy oraz godziny <br /> niestandardowe za{" "}
                        <br /> dodatkową opłatą.
                      </div>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label row-tooltip row-tooltip--2">
                      Prowadzenie kampanii wizerunkowej w Facebook Ads
                      <span className="star">**</span>
                      <div className="tooltip tooltip--2">
                        <span className="star">**</span> Kampania z celem
                        budowania społeczności (promocja postów, nowi fani i
                        obserwatorzy). W przypadku innych celów, takich jak
                        kampanie leadowe, generowanie sprzedaży, zapisy itp.
                        tworzona jest dedykowana strategia reklamy. Budżet
                        mediowy stanowi dodatkową inwestycję po stronie klienta.{" "}
                      </div>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                    <td>
                      <span className="circle"></span>
                    </td>
                  </tr>
                  <tr>
                    <td className="row-label">Raportowanie</td>
                    <td>Zaawansowane raporty przy wykorzystaniu NapoleonCat</td>
                    <td>Zaawansowane raporty przy wykorzystaniu NapoleonCat</td>
                    <td>Zaawansowane raporty przy wykorzystaniu NapoleonCat</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div className="text-center">
                        <a
                          href="#kontakt"
                          className="btn btn-box prowadzenie-fb-wycena-1"
                          onClick={VictorBtnAction}
                        >
                          <strong>zapytaj o wycenę</strong>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a
                          href="#kontakt"
                          className="btn btn-box prowadzenie-fb-wycena-2"
                          onClick={WinnerBtnAction}
                        >
                          <strong>zapytaj o wycenę</strong>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <a
                          href="#kontakt"
                          className="btn btn-box prowadzenie-fb-wycena-2"
                          onClick={ChampionBtnAction}
                        >
                          <strong>zapytaj o wycenę</strong>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-testimonials">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
            <h2 className="single-offer-section-testimonials__title">
              Zobacz, co myślą o nas nasi klienci
            </h2>
            <div className="testimonials-container">
              <div className="single-testimonial">
                <span className="single-testimonial__pseudo pseudo pseudo--square-empty"></span>
                <p className="single-testimonial__text">
                  Nasza ulubiona agencja :) kontakt super (Pani Weronika
                  fantastyczna! Pomaga, poprawia humor i szybko spełnia nasze
                  prośby). Sprzedaż z miesiąca na miesiąc coraz większa.
                  Współpraca z innymi agencjami to nie było to, a tu czekamy na
                  więcej. <br />
                  <strong>Paulina Wera</strong>
                </p>
                <span className="single-testimonial__pseudo pseudo pseudo--circle-empty"></span>
              </div>
              <div className="single-testimonial">
                <span className="single-testimonial__pseudo pseudo pseudo--circle-full"></span>
                <p className="single-testimonial__text">
                  When, to przede wszystkim bardzo rzetelny i pomocny zespół po
                  prostu fajnych i uczciwych ludzi. Mój projekt w końcu jest w
                  bezpiecznych i profesjonalnych rękach. Jestem przekonana, iż
                  przy takim zaangażowaniu i takiej współpracy osiągniemy
                  wyznaczone cele. Po stokroć polecam! <br />
                  <strong>Aldona Sosnowska</strong>
                </p>
              </div>
              <div className="single-testimonial">
                <span className="single-testimonial__pseudo pseudo pseudo--square-full"></span>
                <p className="single-testimonial__text">
                  Jestem pod wrażeniem wysokiego poziomu obsługi klienta. Każdy
                  jest traktowany jak klient VIP, niezależnie od budżetu
                  przeznaczonego na kampanię. Polecam!
                  <br />
                  <strong>Katarzyna Clara-Olędzka</strong>
                </p>
                <span className="single-testimonial__pseudo pseudo pseudo--circle-empty-big"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-case-study">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <h2 className="case-col__title">
              Sprawdź nasze przykładowe <br /> case studies
            </h2>
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/strategy-sm-case-1.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">branża hotelarska</p>
              <p className="post-wrapper__title">
                Społeczność <span>30 000</span> zaangażowanych fanów obiektu
                hotelowego Blue Mountain Resort w ciągu 2 lat.
              </p>
              <p className="post-wrapper__desc">
                Praca nad budową społeczności od zera to nasze ulubione zadanie.
                Sprawdź nasze case study prowadzenia strony na Facebooku dla
                4-gwiazdkowego hotelu wypoczynkowego Blue Mountain Resort od
                pierwszego dnia istnienia obiektu.
              </p>
              <div className="text-wrapper--inner-action">
                <Link
                  to="/case-study/blue-mountain-resort/"
                  className="btn btn-line"
                >
                  więcej
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
            <div className="post-wrapper">
              <div className="post-wrapper__thumbnail-container thumbnail-container">
                <div className="rectangle"></div>
                <img
                  className="img-fluid thumbnail-container__image image"
                  src={require("../assets/images/strategy-sm-case-2.png")}
                  alt=""
                />
              </div>
              <p className="post-wrapper__category">branża nieruchomości</p>
              <p className="post-wrapper__title">
                Magiczna kraina, fascynująca kultura i tylko 44 grosze za
                pozyskanie nowego fana strony na Facebooku Amazing Thailand
                Poland
              </p>
              <p className="post-wrapper__desc">
                Zobacz nasze case study z 2-letniej współpracy nad budową
                społeczności użytkowników zakochanych w Tajlandii na zlecenie
                Tourism Authority of Thailand
              </p>
              <div className="text-wrapper--inner-action">
                <Link to="/amazing-thailand-poland/" className="btn btn-line">
                  więcej
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span className="anchor" id="kontakt"></span>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} facebookSelect={true} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default ProwadzenieFacebookPage;
